import { HttpErrorResponse, PK, ReactiveForm, ReactiveFormData } from '@amirsavand/ngx-common';
import { NgxSnackbarService, Snackbar } from '@amirsavand/ngx-snackbar';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '@app/shared/services/title.service';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  /** Snackbars to remove when page changes. */
  private pageSnackbars: Snackbar[] = [];

  /** Elements to remove when page changes. */
  private pageElements: HTMLElement[] = [];

  /** Current activated route. */
  private activatedRoute?: ActivatedRoute;

  /**
   * Trigger to cancel pending requests related to
   * tenant.
   *
   * Used to cancel tenant requests when user is not
   * in tenant pages anymore and logic that requires
   * tenant data won't be executed since the data
   * has been cleared when user leaves a tenant or
   * switches to a new one.
   */
  public readonly tenantPendingRequests = new Subject<void>();

  /** Triggers when page changes. */
  public readonly pageChange = new EventEmitter();

  /**
   * On production, do not allow tenant creation unless
   * debug is set in localStorage.
   *
   * Allow on other environments.
   */
  public readonly tenantCreationEnabled: boolean = Boolean(
    environment.name !== 'production' || 'debug' in (this.document.defaultView?.localStorage || {}),
  );

  /** @true if platform is mobile. */
  public get isPlatformMobile(): boolean {
    return this.document.defaultView?.matchMedia('(max-width: 576px').matches || false;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly ngxSnackbarService: NgxSnackbarService,
    private readonly titleService: TitleService,
    private readonly router: Router,
  ) {}

  private updatePageTitle(title?: string): void {
    if (title) {
      this.titleService.pageName = title;
    }
  }

  /** Must be triggered when page changes in {@see AppComponent}. */
  public onPageChange(activatedRoute: ActivatedRoute): void {
    /** Store activate page. */
    this.activatedRoute = activatedRoute;
    /** Update page title based on current page. */
    this.updatePageTitle(activatedRoute.snapshot.data['title']);
    /** Remove all page objects. */
    for (const object of [...this.pageSnackbars, ...this.pageElements]) {
      object.remove(true);
    }
    this.pageSnackbars = [];
    this.pageElements = [];
    /** Trigger page change emitter. */
    this.pageChange.emit();
  }

  /**
   * Confirm snackbar for the user.
   *
   * @param text Confirmation message.
   * @param labels Button labels (default is ["Yes", "No"]).
   * @param noAutoRemove Whether to remove on page change.
   *
   * @returns an observable with result of true of false based on yes and no button .
   */
  public confirm(text: string, labels?: string[], noAutoRemove?: boolean): Observable<boolean> {
    const subject = new Subject<boolean>();
    labels = labels || ['Yes', 'No'];
    const snackbar: Snackbar = this.ngxSnackbarService.add({
      text,
      style: 'dark',
      lifetime: 0,
      dismissible: false,
      buttons: {
        [labels[0]]: (snackbar: Snackbar): void => {
          snackbar.remove(true);
          subject.next(true);
        },
        [labels[1]]: (snackbar: Snackbar): void => {
          snackbar.remove(true);
          subject.next(false);
        },
      },
    });
    if (!noAutoRemove) {
      this.pageSnackbars.push(snackbar);
    }
    return subject;
  }

  /**
   * Same as {@see confirm} but specific for
   * destroying objects via API.
   */
  public confirmDestroy<T extends ReactiveFormData>(data: {
    /** Name of the object that will be deleted. */
    name: string;
    /** Instance of the reactive form. */
    form: ReactiveForm<T>;
    /** Do not use the form loading. */
    noLoading?: boolean;
    /** Do not make a snackbar when API call succeeds. */
    noSuccessAlert?: boolean;
    /** Navigate when API call succeeds (optional). */
    noNavigateToParent?: boolean;
    /** Object parent PK for API (optional). */
    parentPk?: PK;
  }): Observable<boolean | HttpErrorResponse> {
    if (!data.noLoading) {
      data.form.loading = true;
    }
    const subject = new Subject<boolean | HttpErrorResponse>();
    this.confirm(`Are you sure you want to delete this ${data.name}?`).subscribe({
      next: (result: boolean): void => {
        if (!result) {
          if (!data.noLoading) {
            data.form.loading = false;
          }
          subject.next(false);
          return;
        }
        if (!data.noLoading) {
          data.form.loading = true;
        }
        data.form.crud?.destroy(data.form.id as PK, data.parentPk).subscribe({
          next: (): void => {
            if (!data.noLoading) {
              data.form.loading = false;
            }
            if (!data.noNavigateToParent) {
              this.router.navigate(['..'], { relativeTo: this.activatedRoute });
            }
            if (!data.noSuccessAlert) {
              this.ngxSnackbarService.add({
                text: `${data.name} deleted successfully.`,
                style: 'success',
              });
            }
            subject.next(true);
          },
          error: (error: HttpErrorResponse): void => {
            if (!data.noLoading) {
              data.form.loading = false;
            }
            subject.next(error);
          },
        });
      },
    });
    return subject.asObservable();
  }

  /**
   * Snackbar with error style for general API errors.
   * Used for places where we don't have a place for
   * the <app-general-error>.
   *
   * @param errors HTTP error response.
   * @param fallback Default error message if errors
   * did not contain anything. Set to "" to disable
   * fallback text.
   *
   * @returns Snackbar instance if alert was shown.
   * @returns null if no alert was shown.
   */
  public alertErrorApi(errors: HttpErrorResponse, fallback = 'Failed to complete action.'): Snackbar | null {
    /** Setup text for extracting. */
    let text: string | undefined;
    /** If error has "non_field_errors". */
    if (errors.error.non_field_errors) {
      text = errors.error.non_field_errors[0];
    } else if (errors.error.detail) {
      /** If error has "detail". */
      text = errors.error.detail;
    }
    /** Use fallback text if a text was not found. */
    if (!text && fallback) {
      text = fallback;
    }
    /** If text is extracted, show error.*/
    if (text) {
      return this.ngxSnackbarService.add({ text, style: 'danger' });
    }
    return null;
  }

  /**
   * Make a success alert with default values with
   * the given text.
   *
   * @param text Alert text.
   *
   * @returns Snackbar instance.
   */
  public alertSuccess(text: string): Snackbar {
    return this.ngxSnackbarService.add({ text, style: 'success' });
  }

  /**
   * Make an error alert with default values with
   * the given text.
   *
   * @param text Alert text.
   *
   * @returns Snackbar instance.
   */
  public alertError(text: string): Snackbar {
    return this.ngxSnackbarService.add({ text, style: 'danger' });
  }
}
