import { TitleBaseService } from '@amirsavand/ngx-common';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

/** @see TitleServiceBase */
@Injectable({
  providedIn: 'root',
})
export class TitleService extends TitleBaseService {
  constructor(title: Title) {
    super(title, 'Savand Bros Chat');
  }
}
